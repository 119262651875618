<template>
    <div>
        
        <v-card elevation="0">
            <validation-observer ref="observer">
                <v-form class="mt-4">
                    <v-container class="pt-5">
                        <!--VOLNEI-->
                        <v-row>
                            <v-col md="2" class="d-none d-md-block" />
                            <v-col md="8" sm="12">
                                <v-row>
                                    <v-col md="12" sm="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            vid="nome"
                                        >
                                            <v-text-field
                                                v-model="areaNova.nome"
                                                outlined
                                                dense
                                                :readonly="readonly"
                                                hide-details="auto"
                                                :disabled="$store.state.layout.loading"
                                                label="Nome"
                                                :error-messages="errors"
                                            />
                                        </validation-provider>
                                    </v-col>
                                </v-row>
                                <h3 class="mt-2 mb-2">Localização da área</h3>
                                <v-row>
                                    <v-col md="8" sm="12" cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            vid="endereco.logradouro"
                                        >
                                            <v-text-field
                                                v-model="
                                                    areaNova.endereco.logradouro
                                                "
                                                outlined
                                                dense
                                                :readonly="readonly"
                                                required
                                                hide-details="auto"
                                                :disabled="$store.state.layout.loading"
                                                label="Logradouro"
                                                :error-messages="errors"
                                            />
                                        </validation-provider>
                                    </v-col>

                                    <v-col md="4" sm="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            vid="endereco.numero"
                                        >
                                            <v-text-field
                                                v-model="
                                                    areaNova.endereco.numero
                                                "
                                                v-mask="'######'"
                                                outlined
                                                :readonly="readonly"
                                                dense
                                                hide-details="auto"
                                                :disabled="$store.state.layout.loading"
                                                label="Número"
                                                :error-messages="errors"
                                            />
                                        </validation-provider>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col md="4" sm="12" cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            vid="complemento"
                                        >
                                            <v-text-field
                                                v-model="
                                                    areaNova.endereco
                                                        .complemento
                                                "
                                                outlined
                                                dense
                                                hide-details="auto"
                                                :readonly="readonly"
                                                :disabled="$store.state.layout.loading"
                                                label="Complemento"
                                                :error-messages="errors"
                                            />
                                        </validation-provider>
                                    </v-col>
                                    <v-col md="4" sm="12" cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            vid="bairro"
                                        >
                                            <v-text-field
                                                v-model="
                                                    areaNova.endereco.bairro
                                                "
                                                outlined
                                                dense
                                                hide-details="auto"
                                                :readonly="readonly"
                                                :disabled="$store.state.layout.loading"
                                                label="Bairro"
                                                :error-messages="errors"
                                            />
                                        </validation-provider>
                                    </v-col>
                                    <v-col md="4" sm="12" cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            vid="endereco.cep"
                                        >
                                            <v-text-field
                                                v-model="areaNova.endereco.cep"
                                                v-mask="'#####-###'"
                                                outlined
                                                :readonly="readonly"
                                                dense
                                                hide-details="auto"
                                                :disabled="$store.state.layout.loading"
                                                label="CEP"
                                                :error-messages="errors"
                                            />
                                        </validation-provider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" sm="12" cols="12">
                                        <autocomplete-estados
                                            :readonly="readonly"
                                            v-model="estadoId"
                                        />
                                    </v-col>
                                    <v-col md="4" sm="12" cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            vid="endereco.cidade_id"
                                        >
                                            <autocomplete-cidades
                                                :readonly="readonly"
                                                v-model="
                                                    areaNova.endereco.cidade_id
                                                "
                                                :estado-id="estadoId"
                                                :error-messages="errors"
                                            />
                                        </validation-provider>
                                    </v-col>
                                    <v-col
                                        md="4"
                                        sm="12"
                                        cols="12"
                                        class="d-flex justify-center mt-2"
                                    >
                                        <v-switch
                                            v-model="areaNova.ativo"
                                            class="mt-0 pt-0"
                                            :readonly="readonly"
                                            :disabled="$store.state.layout.loading"
                                            :label="descricaoAtivo"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col md="2" class="d-none d-md-block" />
                        </v-row>
                    </v-container>
                </v-form>
            </validation-observer>
            <v-divider class="mt-4" />
            <v-card-actions>
                <v-row>
                    <v-col cols="12" class="text-left">
                        <btn-salvar
                            :temPermissao="
                                $store.getters['usuario/temPermissao'](
                                    'editar.areas'
                                )
                            "
                            :disabled="salvarDisabled"
                            :carregando="carregandoSalvar"
                            @click="$emit('salvar', areaNova)"
                        />
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import _ from "lodash";

export default {
    props: {
        validacaoFormulario: {
            type: Object,
            default: () => {
                return {};
            },
        },
        carregandoSalvar: {
            type: Boolean,
            default: false,
        },
 
        salvarDisabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        area: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },

    data() {
        return {
            menu: false,
            estadoId: null,
            areaNova: {
                nome: null,
                cpf_cnpj: null,
                ativo: true,
                endereco: {
                    endereco_id: null,
                    logradouro: null,
                    bairro: null,
                    numero: null,
                    cep: null,
                    cidade_id: null,
                },
            },
        };
    },
    computed: {
        descricaoAtivo() {
            return this.areaNova.ativo ? "Ativo" : "Inativo";
        },
    },
    watch: {
        validacaoFormulario(val) {
            if (!val) return;

            return this.$refs.observer.setErrors(val);
        },

        area(area) {
            var endereco_id = null;
            if (_.isEmpty(area)) return false;
            this.estadoId = 23;
            if (!_.isEmpty(area.endereco.cidade)) {
                this.estadoId = area.endereco.cidade.estado_id;
                endereco_id = area.endereco.id;
            }

            area = { ...area, ...{ endereco: area.endereco } };

            this.areaNova = area;
            this.areaNova.endereco.endereco_id = endereco_id;
        },
    },
};
</script>
