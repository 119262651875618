var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"elevation":"0"}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{staticClass:"mt-4"},[_c('v-container',{staticClass:"pt-5"},[_c('v-row',[_c('v-col',{staticClass:"d-none d-md-block",attrs:{"md":"2"}}),_c('v-col',{attrs:{"md":"8","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"vid":"nome"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":_vm.readonly,"hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Nome","error-messages":errors},model:{value:(_vm.areaNova.nome),callback:function ($$v) {_vm.$set(_vm.areaNova, "nome", $$v)},expression:"areaNova.nome"}})]}}])})],1)],1),_c('h3',{staticClass:"mt-2 mb-2"},[_vm._v("Localização da área")]),_c('v-row',[_c('v-col',{attrs:{"md":"8","sm":"12","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"endereco.logradouro"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":_vm.readonly,"required":"","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Logradouro","error-messages":errors},model:{value:(
                                                _vm.areaNova.endereco.logradouro
                                            ),callback:function ($$v) {_vm.$set(_vm.areaNova.endereco, "logradouro", $$v)},expression:"\n                                                areaNova.endereco.logradouro\n                                            "}})]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('validation-provider',{attrs:{"vid":"endereco.numero"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"outlined":"","readonly":_vm.readonly,"dense":"","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Número","error-messages":errors},model:{value:(
                                                _vm.areaNova.endereco.numero
                                            ),callback:function ($$v) {_vm.$set(_vm.areaNova.endereco, "numero", $$v)},expression:"\n                                                areaNova.endereco.numero\n                                            "}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"4","sm":"12","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"complemento"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"auto","readonly":_vm.readonly,"disabled":_vm.$store.state.layout.loading,"label":"Complemento","error-messages":errors},model:{value:(
                                                _vm.areaNova.endereco
                                                    .complemento
                                            ),callback:function ($$v) {_vm.$set(_vm.areaNova.endereco
                                                    , "complemento", $$v)},expression:"\n                                                areaNova.endereco\n                                                    .complemento\n                                            "}})]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"12","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"bairro"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"auto","readonly":_vm.readonly,"disabled":_vm.$store.state.layout.loading,"label":"Bairro","error-messages":errors},model:{value:(
                                                _vm.areaNova.endereco.bairro
                                            ),callback:function ($$v) {_vm.$set(_vm.areaNova.endereco, "bairro", $$v)},expression:"\n                                                areaNova.endereco.bairro\n                                            "}})]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"12","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"endereco.cep"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],attrs:{"outlined":"","readonly":_vm.readonly,"dense":"","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"CEP","error-messages":errors},model:{value:(_vm.areaNova.endereco.cep),callback:function ($$v) {_vm.$set(_vm.areaNova.endereco, "cep", $$v)},expression:"areaNova.endereco.cep"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"4","sm":"12","cols":"12"}},[_c('autocomplete-estados',{attrs:{"readonly":_vm.readonly},model:{value:(_vm.estadoId),callback:function ($$v) {_vm.estadoId=$$v},expression:"estadoId"}})],1),_c('v-col',{attrs:{"md":"4","sm":"12","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"endereco.cidade_id"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('autocomplete-cidades',{attrs:{"readonly":_vm.readonly,"estado-id":_vm.estadoId,"error-messages":errors},model:{value:(
                                                _vm.areaNova.endereco.cidade_id
                                            ),callback:function ($$v) {_vm.$set(_vm.areaNova.endereco, "cidade_id", $$v)},expression:"\n                                                areaNova.endereco.cidade_id\n                                            "}})]}}])})],1),_c('v-col',{staticClass:"d-flex justify-center mt-2",attrs:{"md":"4","sm":"12","cols":"12"}},[_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"readonly":_vm.readonly,"disabled":_vm.$store.state.layout.loading,"label":_vm.descricaoAtivo},model:{value:(_vm.areaNova.ativo),callback:function ($$v) {_vm.$set(_vm.areaNova, "ativo", $$v)},expression:"areaNova.ativo"}})],1)],1)],1),_c('v-col',{staticClass:"d-none d-md-block",attrs:{"md":"2"}})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12"}},[_c('btn-salvar',{attrs:{"temPermissao":_vm.$store.getters['usuario/temPermissao'](
                                'editar.areas'
                            ),"disabled":_vm.salvarDisabled,"carregando":_vm.carregandoSalvar},on:{"click":function($event){return _vm.$emit('salvar', _vm.areaNova)}}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }